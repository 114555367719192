<template>
  <div class="flix-form-group">
    <label class="flix-label" v-if="label" :for="id"
      >{{ label }} <help class="flix-help" v-if="help">{{ help }}</help></label
    >
    <input
      class="flix-form-control"
      :class="{ 'flix-border-danger': error }"
      v-model="value"
      :id="id"
      name="title"
      type="password"
      spellcheck="false"
      :placeholder="placeholder"
      :style="styling.input"
    />
    <span
      v-if="error"
      class="flix-text-danger flix-html-strong flix-html-small"
      >{{ error }}</span
    >
  </div>
</template>
<script>
import def from "./inputText.vue";
export default {
  extends: def,
  methods: {
    checkValue() {
      this.check = true;
    }
  }
};
</script>
